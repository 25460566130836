import React, { Component, Fragment, useEffect } from 'react';
import { Link } from 'gatsby';
import {
  AwardsList,
  Clients,
  TransparentTextStage,
  VideoStage,
  FooterNav,
  HorizontalSlider,
  FullViewportStage,
  Carousel,
  CarouselScreen,
  SlideTransition,
  ScrollTransition,
  SplitBG,
  Footer,
} from '../../components';
import { sortByKey } from '../../helper/helper';
import { getLocaleFromPathname, getLinkTo } from '../../i18n/helpers';
import ScrollDown from '../../components/ScrollDown/ScrollDown';
import './Home.scss';

const LastScreen = ({
  scrollTop,
  scrollToTop,
  goToScreen,
  home,
  location,
  readMore,
}) => {
  const goToTop = async () => {
    goToScreen(0, true);
  };

  useEffect(() => {
    const hash = location.hash;
    if (hash.length) {
      goToScreen(hash, true);
    }
  }, []);

  return (
    <FullViewportStage black centered>
      <FooterNav location={location} />
      <Footer onArrowClick={() => goToTop()} />
    </FullViewportStage>
  );
};

const AboveTheFold = ({ goToScreen, location, home, visitGallery }) => {
  return (
    <FullViewportStage centered>
      <h1 className={'typography__header1'}>{home.title}</h1>
      <Link
        to={getLinkTo(
          getLocaleFromPathname(location.pathname),
          getLocaleFromPathname(location.pathname) === 'en'
            ? 'gallery'
            : 'galerie'
        )}
      >
        <p className={'typography__link typography__link--readMoreAbsolute'}>
          {visitGallery}
        </p>
      </Link>
      <ScrollDown onClick={() => goToScreen(1)} />
    </FullViewportStage>
  );
};

class Home extends Component {
  render() {
    const { visitGallery, readMore, seeAll } = this.props.pageContext.text.home;
    const { home, awards, clients, progressSteps } = this.props.data;
    const { location } = this.props;

    const sliderData = sortByKey(progressSteps, 'step').map(step => ({
      image: step.highResImage,
      title: step.stepName,
      link: {
        target: getLinkTo(
          getLocaleFromPathname(location.pathname),
          getLocaleFromPathname(location.pathname) === 'en'
            ? 'services'
            : 'angebot'
        ),
        label: readMore,
      },
    }));

    return (
      <div className="home">
        <VideoStage
          videoId={home.videoID}
          videoBackground
          imageBackgroundSrc={home.titleImageHighRes}
          fixedBackground
        />
        <Carousel transitionTime={1} location={location}>
          <CarouselScreen transition={ScrollTransition}>
            <AboveTheFold
              props={this.props}
              home={home}
              visitGallery={visitGallery}
              location={location}
            />
          </CarouselScreen>
          <CarouselScreen transition={ScrollTransition}>
            <TransparentTextStage text={home.claim1.claim1}>
              <Link
                to={getLinkTo(
                  getLocaleFromPathname(location.pathname),
                  getLocaleFromPathname(location.pathname) === 'en'
                    ? 'about'
                    : 'ueber-uns'
                )}
              >
                <p className={'typography__link'}>{readMore}</p>
              </Link>
            </TransparentTextStage>
          </CarouselScreen>
          <CarouselScreen transition={ScrollTransition}>
            <FullViewportStage centered black>
              <div className="home__progress">
                <HorizontalSlider
                  data={sliderData}
                  fixedHeadline={home.servicesHeadline}
                />
              </div>
            </FullViewportStage>
          </CarouselScreen>
          <CarouselScreen transition={SlideTransition} scrollable={true}>
            <FullViewportStage centered black regularOverflow>
              <Clients
                isReduced
                headline={home.clientsHeadline}
                clients={sortByKey(clients, 'clientName')}
                seeAll={seeAll}
                pathname={location.pathname}
              />
            </FullViewportStage>
          </CarouselScreen>
          <CarouselScreen transition={SlideTransition} scrollable={true}>
            <FullViewportStage centered black regularOverflow>
              <h2 className={'typography__header2 typography--uppercase'}>
                {home.awardsHeadline}
              </h2>
              <AwardsList awards={awards} showSelected />
              <Link
                to={`${getLinkTo(
                  getLocaleFromPathname(location.pathname),
                  getLocaleFromPathname(location.pathname) === 'en'
                    ? 'about'
                    : 'ueber-uns'
                )}#8`}
              >
                <p className={'typography__link typography__link--readMore'}>
                  {seeAll}
                </p>
              </Link>
            </FullViewportStage>
          </CarouselScreen>
          <CarouselScreen scrollable={true} transition={SlideTransition}>
            <FullViewportStage centered black regularOverflow>
              <SplitBG images={home.teamImages}>
                <h2 className={'typography__header2 typography--uppercase'}>
                  {home.teamHeadline}
                </h2>
                <Link
                  to={`${getLinkTo(
                    getLocaleFromPathname(location.pathname),
                    getLocaleFromPathname(location.pathname) === 'en'
                      ? 'about'
                      : 'ueber-uns'
                  )}#6`}
                >
                  <p className={'typography__link typography__link--readMore'}>
                    {readMore}
                  </p>
                </Link>
              </SplitBG>
            </FullViewportStage>
          </CarouselScreen>
          <CarouselScreen transition={SlideTransition}>
            <VideoStage
              videoId={home.videoID}
              videoBackground
              imageBackgroundSrc={home.titleImageHighRes}
            />
            <TransparentTextStage
              text={home.claim1.claim1}
              iconPath={'assets/icons/heart.svg'}
            >
              <Link
                to={getLinkTo(
                  getLocaleFromPathname(location.pathname),
                  getLocaleFromPathname(location.pathname) === 'en'
                    ? 'services'
                    : 'angebot'
                )}
              >
                <p className={'typography__link typography__link--readMore'}>
                  {readMore}
                </p>
              </Link>
            </TransparentTextStage>
          </CarouselScreen>
          <CarouselScreen transition={SlideTransition} scrollable={true}>
            <LastScreen home={home} location={location} readMore={readMore} />
          </CarouselScreen>
        </Carousel>
      </div>
    );
  }
}

export default Home;
