import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Layout/Layout';
import Home from './Home';
import '../../contentful/fragments';

export default props => {
  const data = {
    home: props.data.home.nodes[0],
    awards: props.data.awards.nodes,
    clients: props.data.clients.nodes,
    progressSteps: props.data.progressSteps.nodes,
  };
  return (
    <Layout>
      <Home {...props} data={data} />
    </Layout>
  );
};

export const query = graphql`
  query($locale: String!) {
    home: allContentfulHomePage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        ...homePageFragment
      }
    }

    awards: allContentfulAward(filter: { node_locale: { eq: $locale } }) {
      nodes {
        title
        isVisibleOnLanding
      }
    }

    clients: allContentfulClient(filter: { node_locale: { eq: $locale } }) {
      nodes {
        clientName
        clientLogo {
          title
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }

    progressSteps: allContentfulProgressStep(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        highResImage {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        stepName
        step
      }
    }
  }
`;
